/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-01-03",
    versionChannel: "nightly",
    buildDate: "2023-01-03T00:14:54.147Z",
    commitHash: "0e2fbe4cf79011710b71191fb58e13b9811c72dc",
};
